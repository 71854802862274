import {
  Drawer,
  Spin,
  Tag,
  Timeline,
  Typography,
  Descriptions,
  Divider,
  Tooltip,
  Space,
  Statistic,
  Row,
  Col,
} from 'antd'
import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { InfoCircleFilled } from '@ant-design/icons'
import { sortBy, uniqBy } from 'lodash'
import { format as dateFormat } from 'date-fns'
import ReactJson from 'react-json-view'

const { Text } = Typography

const SUBSCRIPTION_QUERY = gql`
  query($id: MongoID!) {
    subscription: subscriptionById(_id: $id) {
      _id
      status
      history
      meta
      paypalId
      plan {
        name
        _id
        website {
          _id
          name
        }
        price {
          value
          currency_code
        }
        frequency {
          interval_count
          interval_unit
        }
      }
    }
  }
`

const STATUS_TYPES = {
  APPROVAL_PENDING: 'warning',
  CANCELLED: 'danger',
  ACTIVE: 'success',
}

export default function SubscriptionDetail({ id, onClose }) {
  const { data, loading } = useQuery(SUBSCRIPTION_QUERY, {
    skip: id === null,
    variables: { id },
  })

  const subscription = data?.subscription
  const history = uniqBy(sortBy(subscription?.history ?? [], 'create_time'), 'id')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: subscription?.plan?.price?.currency_code ?? 'USD',
  })

  let meta = null
  try {
    meta = JSON.parse(history?.[0]?.resource.custom_id)
  } catch {}

  let customerId = meta?.cid ?? meta?.[0]

  return (
    <Drawer
      visible={id !== null}
      onClose={onClose}
      title={
        <>
          Subscription <strong>{id}</strong>
        </>
      }
      width={'85%'}
    >
      <Spin spinning={loading}>
        <Row>
          <Col xs={24} sm={12} md={6} lg={4}>
            <Statistic title="Plan" value={subscription?.plan?.name} />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <Statistic title="Website" value={subscription?.plan?.website?.name} />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <Statistic title="Price" value={formatter.format(subscription?.plan?.price?.value)} />
          </Col>
          <Col xs={24} sm={12} md={6} lg={4}>
            <Statistic
              title="Frequency"
              value={subscription?.plan?.frequency?.interval_count ?? '–'}
              suffix={subscription?.plan?.frequency?.interval_unit}
            />
          </Col>
          {meta?.cid && (
            <Col xs={24} sm={12} md={6} lg={4}>
              <Statistic title="Customer ID" value={' ' + customerId.toString()} />
            </Col>
          )}
        </Row>
        <Divider orientation="left">Customer Meta</Divider>
        {meta && <ReactJson src={meta} name="Customer" />}
        <Divider orientation="left">Events History</Divider>
        {history.length && (
          <Timeline mode="left">
            {history.map((item) => {
              const date = new Date(item.create_time)

              return (
                <Timeline.Item
                  label={
                    <small>
                      {dateFormat(date, 'yyyy-MM-dd')}
                      <br />
                      {dateFormat(date, 'HH:mm:ss')}
                    </small>
                  }
                  key={item.id}
                >
                  <Text type={STATUS_TYPES[item.resource?.status] ?? 'default'} strong>
                    {item.summary}
                  </Text>
                  <div>
                    <small>
                      <Text type="secondary">{item.event_type}</Text>
                    </small>
                  </div>
                  {item.resource?.subscriber && <SubscriberInfo {...item.resource.subscriber} />}
                  <StatusInfo item={item.resource} />
                  <ReactJson src={item} name="Details" collapsed={true} style={{ marginTop: 16 }} />
                </Timeline.Item>
              )
            })}
          </Timeline>
        )}
      </Spin>
    </Drawer>
  )
}

function SubscriberInfo({ email_address, payer_id, name, shipping_address }) {
  const address = shipping_address?.address
  return (
    <>
      <Divider orientation="left">Subscriber</Divider>
      <Descriptions layout="vertical" bordered size="small">
        <Descriptions.Item label="ID">{payer_id}</Descriptions.Item>
        <Descriptions.Item label="Name">
          {name?.given_name} {name?.surname}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{email_address}</Descriptions.Item>
        <Descriptions.Item label="Country">{address?.country_code}</Descriptions.Item>
        <Descriptions.Item label="Postal code">{address?.postal_code}</Descriptions.Item>
        <Descriptions.Item label="City">{address?.admin_area_2}</Descriptions.Item>
        <Descriptions.Item label="Address">
          {[address?.address_line_1, address?.address_line_2].filter(Boolean).join(', ')}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

function StatusInfo({ item }) {
  const notes = item.status_change_note
  return (
    <>
      <Divider orientation="left">Status</Divider>
      <Space align="center">
        <Tag>{item.status}</Tag>
        {notes && (
          <Tooltip title={notes} placement="bottom">
            <InfoCircleFilled />
          </Tooltip>
        )}
      </Space>
    </>
  )
}