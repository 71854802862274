import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

export default function createApolloClient() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql',
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('idToken')

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
