import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Col, Divider, Form, Input, message, PageHeader, Row, Space, Spin, Switch } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Layout from '../Layout'

import { WEBSITES_QUERY } from './List'

const WEBSITE_ADD_MUTATION = gql`
  mutation($data: CreateOneWebsiteInput!) {
    result: websitesInsert(record: $data) {
      recordId
    }
  }
`

const WEBSITE_UPDATE_MUTATION = gql`
  mutation($id: MongoID!, $data: UpdateByIdWebsiteInput!) {
    result: websitesUpdate(_id: $id, record: $data) {
      recordId
    }
  }
`

const WEBSITE_QUERY = gql`
  query($id: MongoID!) {
    website: websiteById(_id: $id) {
      _id
      url
      name
      apiKey
      webhookUrl
      paypalClientId
      paypalClientSecret
      paypalSandbox
      paypalProductName
      paypalProductId
      allowOrigin
      headers
    }
  }
`

export default function WebsiteForm() {
  const params = useParams()
  const history = useHistory()
  const [form] = Form.useForm()

  const isEditing = Boolean(params.id)

  const { data, loading } = useQuery(WEBSITE_QUERY, {
    skip: !isEditing,
    variables: params,
    onCompleted: (data) => {
      form.setFieldsValue(data?.website)
    },
  })

  const refetchQueries = [{ query: WEBSITES_QUERY, variables: { page: 1 } }]
  if (isEditing) {
    refetchQueries.push({ query: WEBSITE_QUERY, variables: params })
  }

  const [save, { loading: saving }] = useMutation(isEditing ? WEBSITE_UPDATE_MUTATION : WEBSITE_ADD_MUTATION, {
    refetchQueries,
  })

  const submit = async () => {
    try {
      const data = await form.validateFields()

      const {
        data: { result },
      } = await save({
        variables: { data, ...(isEditing && { id: params?.id }) },
      })

      message.success(`Website ${result?.recordId} successfully saved`)
      history.push('/websites')
    } catch (err) {
      console.error(err)

      if (err.errorFields) {
        return
      }

      message.error(err.toString())
    }
  }

  const productId = data?.website?.paypalProductId

  return (
    <Layout>
      <PageHeader title={isEditing ? 'Edit website' : 'Add website'} ghost={false} onBack={() => history.goBack()}/>
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} initialValues={{ paypalSandbox: true }}>
          <Row gutter={32}>
            <Col xs={24} md={8}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="URL" name="url" rules={[{ required: true, message: 'URL is required' }]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Product Name" name="paypalProductName"
                         help={productId ? `Paypal ID: ${productId}` : 'Can\'t be changed after initial creation'}
                         rules={[{ required: true, message: 'Product Name' }]}>
                <Input disabled={Boolean(productId)}/>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Integration</Divider>
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item label="API Key" name="apiKey" help="Leave it empty for auto generated value">
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Webhook URL" name="webhookUrl">
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Allow Origin" name="allowOrigin" help="Comma or new line separated patterns">
                <Input.TextArea rows={5}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Headers" name="headers">
                <Input.TextArea rows={5}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Paypal</Divider>
          <Form.Item label="Sandbox" name="paypalSandbox" valuePropName="checked">
            <Switch/>
          </Form.Item>
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Paypal Client ID"
                name="paypalClientId"
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Paypal Client Secret"
                name="paypalClientSecret"
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Input.Password/>
              </Form.Item>
            </Col>
          </Row>


          <Form.Item>
            <Space>
              <Button type="primary" onClick={() => submit()} disabled={saving} loading={saving}>
                Save
              </Button>
              {isEditing && (
                <Button type="danger" disabled={saving}>
                  Delete
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Layout>
  )
}