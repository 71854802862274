import { useAuth0 } from '@auth0/auth0-react'
import { Button, Result } from 'antd'
import React from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'

export default function Login() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()
  const location = useLocation()
  const { createHref } = useHistory()

  if (isAuthenticated) {
    return <Redirect to={location.state?.from?.pathname ?? '/'} />
  }

  const login = async () => {
    try {
      let redirectUri
      if (location.state?.from) {
        redirectUri = window.location.origin + '/' + createHref(location.state?.from).replace(/^\//, '')
      }

      await loginWithRedirect({ redirectUri })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Result
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      status="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button loading={isLoading} onClick={login} type="primary">
          Sign In
        </Button>
      }
    />
  )
}
