import React, { forwardRef, useImperativeHandle } from 'react'
import { useQuery } from '@apollo/client'
import { Table } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useMedia } from 'react-media'

export function useSearchParams() {
  return new URLSearchParams(useLocation().search)
}

function transformFilters(filters) {
  const result = Object.entries(filters ?? {})
    .filter(([, value]) => (Array.isArray(value) ? value.length > 0 : value !== null))
    .map(([name, value]) => {
      return Array.isArray(value) ? { OR: value.map((value) => ({ [name]: value })) } : { [name]: value }
    })

  if (!result.length) {
    return
  }

  return {
    AND: result,
  }
}

function Grid({ query, columns, queryOptions, prepareFilters, ...props }, ref) {
  const isSmallScreen = useMedia({ query: '(max-width: 599px)' })

  const params = useSearchParams()
  const page = parseInt(params.get('page')) || 1
  const perPage = parseInt(params.get('perPage')) || 10
  const sort = params.get('sortBy')

  let filtersData
  try {
    filtersData = JSON.parse(params.get('filters'))
  } catch {}

  const location = useLocation()
  const history = useHistory()

  const { data, loading, refetch } = useQuery(query, {
    variables: { page, perPage, filters: transformFilters(filtersData), sort },
    fetchPolicy: 'no-cache',
    ...(queryOptions ?? {}),
  })

  useImperativeHandle(ref, () => ({ refetch }))

  const dataSource = data?.result?.items ?? []
  const pageInfo = data?.result?.pageInfo ?? {}

  const onChange = (pagination, filters, sorter) => {
    const { current: page, pageSize: perPage } = pagination
    let newLocation = location.pathname + `?page=${page}&perPage=${perPage}`

    if (Object.values(filters).filter((value) => (Array.isArray(value) ? value.length > 0 : value !== null)).length) {
      newLocation += `&filters=${encodeURIComponent(JSON.stringify(filters))}`
    }

    if (sorter.order) {
      let sortBy = sorter.field.toUpperCase() + '_' + { ascend: 'ASC', descend: 'DESC' }[sorter.order]
      newLocation += `&sortBy=${encodeURIComponent(sortBy)}`
    }

    history.push(newLocation)
  }

  let screenColumns = columns.filter(({ mobileOnly }) => (isSmallScreen ? mobileOnly : !mobileOnly))
  if (!screenColumns.length) {
    screenColumns = columns
  }

  screenColumns = screenColumns.map((item) => {
    if (!item.sorter || !sort) {
      return item
    }

    if (sort.startsWith(item.dataIndex.toUpperCase())) {
      item.defaultSortOrder = sort.endsWith('_DESC') ? 'descend' : 'ascend'
    }

    return item
  })

  return (
    <Table
      columns={screenColumns}
      loading={loading}
      dataSource={dataSource}
      rowKey="_id"
      pagination={{
        pageSize: perPage,
        total: pageInfo.itemCount ?? 0,
        current: page,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: true,
      }}
      onChange={onChange}
      {...props}
      size="small"
    />
  )
}

export default forwardRef(Grid)
