import { ApolloProvider } from '@apollo/client'
import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import initApolloClient from './lib/apollo'
import { HashRouter as Router } from 'react-router-dom'

const authDomain = process.REACT_APP_AUTH_DOMAIN || 'subscriptions.eu.auth0.com'
const authClientId = process.REACT_APP_AUTH_CLIENT_ID || 'xFHJIRY0kf36C2v0rj5xjNMbnJLxIDl3'

const client = initApolloClient()

ReactDOM.render(
  <ApolloProvider client={client}>
    <Auth0Provider domain={authDomain} clientId={authClientId} redirectUri={window.location.origin}>
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </ApolloProvider>,
  document.getElementById('root')
)
