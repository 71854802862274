import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Button, Dropdown, Menu, message, PageHeader, Popconfirm, Space, Switch, Tooltip, Typography } from 'antd'
import React, { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Grid from '../Grid'
import Layout from '../Layout'

export const PLANS_QUERY = gql`
  query plans($page: Int = 0, $perPage: Int = 10) {
    result: plans(page: $page, perPage: $perPage) {
      pageInfo {
        perPage
        currentPage
        itemCount
      }
      items {
        _id
        paypalId
        name
        enabled
        alias
        position
        website {
          _id
          name
        }

        frequency {
          count: interval_count
          unit: interval_unit
        }

        price {
          value
          currency: currency_code
        }
      }
    }
  }
`

const PLAN_REMOVE_MUTATION = gql`
  mutation($id: MongoID!) {
    plansDelete(_id: $id) {
      recordId
    }
  }
`

const PLAN_TOGGLE_MUTATION = gql`
  mutation($id: MongoID!) {
    result: plansToggle(_id: $id) {
      enabled
    }
  }
`

const PlanSummary = (props) => {
  return (
    <>
      <div>
        <Typography.Text type="secondary">{props._id}</Typography.Text>
      </div>
      <div>
        <Typography.Text strong>{props.name}</Typography.Text>
      </div>
      <div>
        <Typography.Text>{props.website?.name}</Typography.Text>
      </div>
    </>
  )
}

const ActionsDropdown = ({ _id }) => {
  const history = useHistory()

  const onMenuItemClick = ({ key }) => {
    switch (true) {
      case key.startsWith('/plans/edit/'):
        history.push(key)
        break
      case key === 'delete':
        break
      default:
        break
    }
  }

  let menu = (
    <Menu onClick={onMenuItemClick}>
      <Menu.Item icon={<EditOutlined/>} key={`/plans/edit/${_id}`}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined/>} key="delete">
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <Button icon={<EllipsisOutlined/>} size="small"/>
    </Dropdown>
  )
}

export default function PlanList() {
  const grid = useRef()
  const [removeById] = useMutation(PLAN_REMOVE_MUTATION)
  const [toggleById] = useMutation(PLAN_TOGGLE_MUTATION)

  const remove = async (id) => {
    const close = message.loading('Deleting...')
    try {
      await removeById({ variables: { id } })
      grid.current?.refetch()
      close()
    } catch (err) {
      await close()
      message.error(err.message ?? err.toString())
    }
  }

  const toggle = async (id) => {
    try {
      await toggleById({ variables: { id }})
      grid.current?.refetch()
    } catch (err) {
      message.error(err.message ?? err.toString())
    }
  }

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title="Subscription Plans"
        extra={[
          <Link to="/plans/add" key="add" component={Button} type="primary">
            New Plan
          </Link>,
        ]}
      />

      <Grid
        ref={grid}
        query={PLANS_QUERY}
        columns={[
          {
            title: 'Plan',
            render: (row) => <PlanSummary {...row} />,
            mobileOnly: true,
          }, {
            dataIndex: '_id',
            render: (id, row) => <ActionsDropdown {...row} onRemove={() => remove(id)}/>,
            mobileOnly: true,
          },
          {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            render: (id, { paypalId }) => (
              <div>
                {id}
                <br/>
                <Tooltip title="PayPal ID">
                  <Typography.Text type="secondary">{paypalId ?? 'NONE'}</Typography.Text>
                </Tooltip>
              </div>
            ),
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, { productName, alias }) => (
              <>
                {name}
                <Typography.Text type="secondary">
                  <small> ({alias})</small>
                </Typography.Text>
              </>
            ),
          },
          {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            render: (website) => <Link to={`/websites/edit/${website?._id}`}>{website?.name}</Link>,
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => `${price.value} ${price.currency}`,
          },
          {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
          },
          {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (frequency) => `${frequency.count} ${frequency.unit}`,
          },
          {
            title: 'Enabled',
            dataIndex: 'enabled',
            align: 'center',
            render: (enabled, { _id }) => <Switch checked={enabled} size="small" onChange={() => toggle(_id)}/>,
          },
          {
            dataIndex: '_id',
            key: 'actions',
            align: 'right',
            render: (id) => (
              <Space>
                <Link to={`/plans/edit/${id}`} component={Button} icon={<EditOutlined/>} size="small"/>
                <Popconfirm
                  placement="bottomRight"
                  title="Are you sure to remove item?"
                  onConfirm={() => remove(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined/>} size="small" type="danger" ghost/>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />
    </Layout>
  )
}