import { InputNumber, Select, Space } from 'antd'
import React from 'react'

export default function IntervalInput({ value = {}, onChange, prefix, disabled = false }) {
  const onUnitsChange = (units) => {
    onChange?.({
      interval_count: value?.interval_count,
      interval_unit: units,
    })
  }

  const onIntervalValueChange = (newValue) => {
    onChange?.({
      interval_count: newValue,
      interval_unit: value?.interval_unit,
    })
  }

  let suffix = value?.interval_count > 1 ? 's' : null

  return (
    <Space align="center">
      {prefix}
      <InputNumber value={value?.interval_count} onChange={onIntervalValueChange} disabled={disabled} />
      <Select value={value?.interval_unit} onChange={onUnitsChange} placeholder="Period" disabled={disabled}>
        <Select.Option value="DAY">Day{suffix}</Select.Option>
        <Select.Option value="WEEK">Week{suffix}</Select.Option>
        <Select.Option value="MONTH">Month{suffix}</Select.Option>
        <Select.Option value="YEAR">Year{suffix}</Select.Option>
      </Select>
    </Space>
  )
}
