import { Editor } from '@tinymce/tinymce-react'
import React from 'react'

export default function Wysiwyg ({ value = '', onChange }) {
  return (
    <Editor
      value={value}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: `undo redo | formatselect | bold italic backcolor |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | removeformat | help`,
      }}
      onEditorChange={(content) => onChange?.(content)}
    />
  )
}