import { useAuth0 } from '@auth0/auth0-react'
import { Button, Result, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import './App.css'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import Plans from './components/Plan/List'
import PlanForm from './components/Plan/Form'
import WebsiteForm from './components/Website/Form'
import Websites from './components/Website/List'
import SubscriptionList from './components/Subscription/List'
import Loader from './components/Loader'

function useAuth() {
  const { isLoading, isAuthenticated, getIdTokenClaims } = useAuth0()
  const [token, setToken] = useState(undefined)
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      if (!isLoading) {
        setToken(null)
      }
      return
    }

    setToken(undefined)
    getIdTokenClaims()
      .then(({ __raw }) => {
        localStorage.setItem('idToken', __raw)
        setToken(__raw)
      })
      .catch((error) => {
        message.error(error.message ?? error.toString())
        setToken(null)
      })

    return () => {}
  }, [isAuthenticated, isLoading, getIdTokenClaims, location])

  return {
    isLoading: isLoading || token === undefined,
    isAuthenticated: isAuthenticated && Boolean(token),
    token,
  }
}

function ProtectedRoute({ children, component, ...rest }) {
  const auth = useAuth()
  const Component = component

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (
          <Component />
        ) : auth.isLoading ? (
          <Loader />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function App() {
  const history = useHistory()

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <ProtectedRoute path="/" exact component={Dashboard} />
      <ProtectedRoute path="/websites" exact component={Websites} />
      <ProtectedRoute path="/websites/add" exact component={WebsiteForm} />
      <ProtectedRoute path="/websites/edit/:id" exact component={WebsiteForm} />
      <ProtectedRoute path="/plans" exact component={Plans} />
      <ProtectedRoute path="/plans/add" exact component={PlanForm} />
      <ProtectedRoute path="/plans/edit/:id" exact component={PlanForm} />
      <ProtectedRoute path="/subscriptions" exact component={SubscriptionList} />
      <Route path="*">
        <Result
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={() => history.replace('/')}>
              Back Dashboard
            </Button>
          }
        />
      </Route>
    </Switch>
  )
}

export default App
