import { gql, useQuery } from '@apollo/client'
import { Card, Col, Divider, PageHeader, Row, Statistic } from 'antd'
import React from 'react'
import Layout from './Layout'

const STATISTICS_QUERY = gql`
  query {
    statistics {
      title
      code
      value
    }
  }
`

const EXTRA = {
  total_usd: { prefix: '$', precision: 2 },
  total_eur: { suffix: '€', precision: 2 },
}

export default function Dashboard() {
  const { data, loading } = useQuery(STATISTICS_QUERY, {
    pollInterval: 5000,
  })

  return (
    <Layout>
      <PageHeader title="Dashboard"/>
      <Divider orientation="left">Subscriptions</Divider>
      <Row gutter={[16, 16]}>
        {(data?.statistics ?? []).map((item) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={4} key={item.title}>
            <Card size="small" key={item.title}>
              <Statistic
                loading={loading}
                key={item.title}
                title={item.title}
                value={item.value}
                {...EXTRA[item.code]}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Layout>
  )
}