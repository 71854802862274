import {
  CheckOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PauseOutlined,
  StopOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'

import { Button, Dropdown, Input, Menu, message, Modal, PageHeader, Space, Tag } from 'antd'
import { format as dateFormat } from 'date-fns'
import { groupBy } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Grid, { useSearchParams } from '../Grid'
import Layout from '../Layout'
import SubscriptionDetail from './Detail'

const SUBSCRIPTIONS_QUERY = gql`
  query (
    $page: Int = 1
    $perPage: Int = 10
    $filters: FilterFindManySubscriptionInput
    $sort: SortFindManySubscriptionInput
  ) {
    result: subscriptions(page: $page, perPage: $perPage, filter: $filters, sort: $sort) {
      pageInfo {
        perPage
        currentPage
        itemCount
      }

      items {
        _id
        paypalId
        status
        updatedAt
        createdAt
        plan {
          name
          _id
          website {
            _id
            name
          }
          price {
            value
            currency_code
          }
          frequency {
            interval_count
            interval_unit
          }
        }
      }
    }
  }
`

const SUBSCRIPTION_UPDATE_STATUS_MUTATION = gql`
  mutation ($id: MongoID!, $status: SubscriptionStatus!, $reason: String!) {
    result: subscriptionUpdateStatus(status: $status, _id: $id, reason: $reason)
  }
`

const PLANS_QUERY = gql`
  query {
    result: plans(perPage: 10000) {
      items {
        _id
        name
        website {
          _id
          name
        }
      }
    }
  }
`

const SUBSCRIPTION_REMOVE_MUTATION = gql`
  mutation ($cond: FilterRemoveManySubscriptionInput!) {
    subscriptionDelete(filter: $cond) {
      numAffected
      error {
        message
      }
    }
  }
`

const STATUS_COLORS = {
  ACTIVE: 'green',
  CANCELLED: 'red',
}

const STATUSES = ['APPROVAL_PENDING', 'APPROVED', 'ACTIVE', 'SUSPENDED', 'CANCELLED', 'EXPIRED']

const buildPlanFilters = (plans = []) => {
  const grouped = groupBy(plans, 'website.name')

  return Object.entries(grouped).map(([name, plans]) => {
    return {
      value: name,
      text: name,
      children: plans.map((plan) => ({
        value: plan._id,
        text: plan.name,
      })),
    }
  })
}

const ACTION_TITLE = {
  activate: 'Activate subscription',
  suspend: 'Suspend subscription',
  cancel: 'Cancel subscription',
}

const SubscriptionActions = ({ id, status, refetch }) => {
  const [reason, setReason] = useState(null)
  const [updateStatus] = useMutation(SUBSCRIPTION_UPDATE_STATUS_MUTATION, {
    variables: {
      id,
      reason,
    },
    onError(error) {
      message.error(error.message ?? error.toString())
    },
    onCompleted: () => refetch?.(),
  })

  const onMenuClick = ({ key }) => {
    Modal.confirm({
      title: ACTION_TITLE[key] ?? 'Change status',
      content: (
        <Input.TextArea showCount maxLength={127} placeholder="Reason..." onChange={(e) => setReason(e.target.value)}>
          {reason}
        </Input.TextArea>
      ),
      onCancel() {},
      async onOk() {
        try {
          await updateStatus({
            variables: {
              id,
              reason,
              status: {
                activate: 'ACTIVE',
                suspend: 'SUSPENDED',
                cancel: 'CANCELLED',
              }[key],
            },
          })
        } catch {}
      },
    })
  }

  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="activate" disabled={status !== 'SUSPENDED'} icon={<CheckOutlined />}>
        Activate
      </Menu.Item>
      <Menu.Item key="suspend" disabled={status !== 'ACTIVE'} icon={<PauseOutlined />}>
        Suspend
      </Menu.Item>
      <Menu.Item key="cancel" disabled={status !== 'ACTIVE'} icon={<StopOutlined />}>
        Cancel
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <Button size="small" icon={<EllipsisOutlined />} />
    </Dropdown>
  )
}

export default function SubscriptionList() {
  const params = useSearchParams()
  const [selected, setSelected] = useState(null)
  const grid = useRef(null)

  const plans = useQuery(PLANS_QUERY)
  const planFilters = buildPlanFilters(plans.data?.result?.items)

  const [selectedIds, setSelectedIds] = useState([])
  const [isDeleting, setDeleting] = useState(false)
  const [performDelete] = useMutation(SUBSCRIPTION_REMOVE_MUTATION, {
    onError(error) {
      console.error(error)
      message.error(error.message ?? error.toString())
    },
    onCompleted: () => grid?.current?.refetch?.(),
  })

  const deleteSelected = useCallback(async () => {
    if (!window.confirm('Are you sure you want to delete selected subscriptions?')) {
      return
    }

    const cond = { OR: selectedIds.map((_id) => ({ _id })) }

    setDeleting(true)
    try {
      await performDelete({ variables: { cond } })
    } finally {
      setDeleting(false)
    }
  }, [performDelete, selectedIds])

  let activeFilters
  try {
    activeFilters = JSON.parse(params.get('filters'))
  } catch {}

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title="Subscriptions"
        extra={[
          <Button
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
            type="primary"
            key="delete"
            disabled={!selectedIds.length || isDeleting}
            onClick={deleteSelected}
          >
            Delete selected
          </Button>,
        ]}
      />
      <Grid
        ref={grid}
        query={SUBSCRIPTIONS_QUERY}
        queryOptions={{
          pollInterval: 10000, // 10 seconds
        }}
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => setSelectedIds(ids),
          selectedRowKeys: selectedIds,
        }}
        columns={[
          {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            sorter: true,
          },
          {
            title: 'PayPal ID',
            dataIndex: 'paypalId',
            key: 'paypalId',
          },
          {
            title: 'Plan',
            dataIndex: 'plan',
            key: 'plan',
            render: (plan) => (
              <>
                <div>
                  <Link to={`/plans/edit/${plan?._id}`}>{plan?.name ?? 'Unknown'}</Link>
                </div>
                <div>
                  <small>
                    <Link to={`/websites/edit/${plan?.website?._id}`}>{plan?.website?.name ?? 'Unknown'}</Link>
                  </small>
                </div>
              </>
            ),
            filters: planFilters,
            defaultFilteredValue: activeFilters?.plan ?? [],
          },
          {
            title: 'Price',
            dataIndex: 'plan',
            key: 'price',
            render: (plan) => {
              if (!plan) {
                return '–'
              }

              const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: plan.price?.currency_code,
              })

              return (
                <div>
                  <div>{formatter.format(plan.price?.value)}</div>
                  <div>
                    <small>
                      {plan.frequency?.interval_count}&nbsp;{plan.frequency.interval_unit}
                    </small>
                  </div>
                </div>
              )
            },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Tag color={STATUS_COLORS[status] ?? 'gold'}>{status}</Tag>,
            defaultFilteredValue: activeFilters?.status ?? [],
            filters: STATUSES.map((name) => ({
              text: name,
              value: name,
            })),
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => (date ? dateFormat(new Date(date), 'PPpp') : '–'),
            sorter: true,
          },
          {
            dataIndex: '_id',
            key: 'actions',
            align: 'right',
            render: (id, { status }) => (
              <Space>
                <Button size="small" icon={<EyeOutlined />} onClick={() => setSelected(id)} />
                <SubscriptionActions id={id} status={status} refetch={grid?.current?.refetch} />
              </Space>
            ),
          },
        ]}
      />
      <SubscriptionDetail id={selected} onClose={() => setSelected(null)} />
    </Layout>
  )
}
