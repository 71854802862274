import {
  CreditCardOutlined,
  DashboardOutlined,
  GlobalOutlined,
  LogoutOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Layout as BaseLayout, Menu } from 'antd'
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const { Content, Footer, Header } = BaseLayout

export default function Layout({ children }) {
  const { logout } = useAuth0()
  const location = useLocation()
  const history = useHistory()

  const onMenuSelect = ({ key }) => {
    if (key === '/logout') {
      localStorage.clear()
      return logout({ returnTo: window.location.origin })
    }

    history.push(key)
  }

  const selected = location.pathname.split('/').slice(0, 2).join('/')

  return (
    <BaseLayout>
      <Header>
        <Menu mode="horizontal" theme="dark" onSelect={onMenuSelect} selectedKeys={[selected]}>
          <Menu.Item icon={<DashboardOutlined />} key="/">
            Dashboard
          </Menu.Item>
          <Menu.Item icon={<GlobalOutlined />} key="/websites">
            Websites
          </Menu.Item>
          <Menu.Item icon={<CreditCardOutlined />} key="/plans">
            Plans
          </Menu.Item>
          <Menu.Item icon={<DollarOutlined />} key="/subscriptions">
            Subscriptions
          </Menu.Item>
          <Menu.Item icon={<LogoutOutlined />} key="/logout">
            Logout
          </Menu.Item>
        </Menu>
      </Header>
      <BaseLayout>
        <Content>
          <div className="content-wrapper">{children}</div>
        </Content>
        <Footer>&copy; {new Date().getFullYear()}</Footer>
      </BaseLayout>
    </BaseLayout>
  )
}
