import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Col, Form, Input, InputNumber, message, PageHeader, Row, Select, Space, Switch } from 'antd'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Layout from '../Layout'
import IntervalInput from '../UI/IntervalInput'
import PriceInput from '../UI/PriceInput'
import Wysiwyg from '../UI/Wysiwyg'
import { WEBSITES_QUERY } from '../Website/List'
import { PLANS_QUERY } from './List'

const PLAN_ADD_MUTATION = gql`
  mutation ($data: CreateOnePlanInput!) {
    result: plansInsert(record: $data) {
      recordId
    }
  }
`

const PLAN_UPDATE_MUTATION = gql`
  mutation ($id: MongoID!, $data: UpdateByIdPlanInput!) {
    result: plansUpdate(_id: $id, record: $data) {
      recordId
    }
  }
`

const PLAN_QUERY = gql`
  query planById($id: MongoID!) {
    result: planById(_id: $id) {
      _id
      alias
      name
      paypalId
      description
      website {
        _id
        name
      }
      frequency {
        interval_count
        interval_unit
      }

      price {
        value
        currency_code
      }

      position

      hasTrialPeriod

      trialPeriod {
        interval_count
        interval_unit
      }

      trialPeriodPrice {
        value
        currency_code
      }
    }
  }
`

export default function PlanForm() {
  const params = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [values, setValues] = useState({})

  const isEditing = Boolean(params.id)

  const [save, { loading: saving }] = useMutation(isEditing ? PLAN_UPDATE_MUTATION : PLAN_ADD_MUTATION, {
    refetchQueries: [{ query: PLANS_QUERY, variables: { page: 1 } }],
  })

  const websites = useQuery(WEBSITES_QUERY, {
    variables: {
      perPage: 1000,
    },
  })

  const { data } = useQuery(PLAN_QUERY, {
    variables: params,
    skip: !isEditing,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let plan = { ...(data?.result ?? {}) }
      plan.website = plan.website?._id
      form.setFieldsValue(plan)
      setValues(plan)
    },
  })

  const submit = async () => {
    try {
      const data = await form.validateFields()

      ;['price', 'frequency', 'trialPeriod', 'trialPeriodPrice'].forEach((key) => delete data[key]?.__typename)

      const {
        data: { result },
      } = await save({ variables: { data, ...(isEditing && { id: params.id }) } })
      message.success(`Plan ${result?.recordId} successfully saved`)
      history.push('/plans')
    } catch (err) {
      if (!err.errorFields?.length > 0) {
        message.error(err.toString())
      }
    }
  }

  return (
    <Layout>
      <PageHeader title={isEditing ? 'Edit Plan' : 'Add Plan'} ghost={false} onBack={() => history.push('/plans')} />
      <Form
        form={form}
        layout="vertical"
        initialValues={{ enabled: true }}
        onValuesChange={(value, values) => {
          setValues(values)
        }}
      >
        <Row gutter={32}>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Name is required' }]}
              help={data?.result?.paypalId}
            >
              <Input placeholder="Plan name..." />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              name="alias"
              label="Alias"
              rules={[{ required: true, message: 'Alias is required' }]}
              help="For internal use"
            >
              <Input placeholder="Alias..." />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item name="website" label="Website" rules={[{ required: true, message: 'Website is required' }]}>
              <Select loading={websites.loading} placeholder="Please select">
                {(websites.data?.result?.items ?? []).map((website) => (
                  <Select.Option value={website._id} key={website._id}>
                    {website.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item name="position" label="Position" help="Order position">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="enabled" label="Enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Space size={32}>
          <Form.Item name="frequency" label="Frequency" rules={[{ required: true, message: 'Frequency is required' }]}>
            <IntervalInput disabled={isEditing} />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Price is required' }]}>
            <PriceInput />
          </Form.Item>
        </Space>
        <Form.Item label="Has trial period?" name="hasTrialPeriod" valuePropName="checked">
          <Switch disabled={isEditing} />
        </Form.Item>
        {values?.hasTrialPeriod && (
          <Space size={32}>
            <Form.Item
              name="trialPeriod"
              label="Trial period"
              rules={[{ required: true, message: 'Trial period is required' }]}
            >
              <IntervalInput disabled={isEditing} />
            </Form.Item>

            <Form.Item
              name="trialPeriodPrice"
              label="Trial period price"
              rules={[{ required: true, message: 'Trial period price is required' }]}
            >
              <PriceInput />
            </Form.Item>
          </Space>
        )}

        <Form.Item name="description" label="Description">
          <Wysiwyg />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" onClick={submit} disabled={saving} loading={saving}>
              Save
            </Button>
            {isEditing && <Button type="danger">Delete</Button>}
          </Space>
        </Form.Item>
      </Form>
    </Layout>
  )
}
