import { CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, Html5Outlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Alert, Button, Dropdown, Menu, PageHeader, Space, Typography } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import Highlight from 'react-highlight'
import { useMedia } from 'react-media'
import { Link, useHistory } from 'react-router-dom'

import useClipboard from 'react-use-clipboard'

import Grid from '../Grid'
import Layout from '../Layout'

export const WEBSITES_QUERY = gql`
  query websites($page: Int = 0, $perPage: Int = 10) {
    result: websites(page: $page, perPage: $perPage) {
      pageInfo {
        perPage
        currentPage
        itemCount
      }
      items {
        _id
        name
        url
        apiKey
      }
    }
  }
`

const generateCode = (apiKey) => `
<script type="text/javascript">
  !function (r,e,p,t,i,f,y) {
    r['ReptifySubscriptionsObject']=t;r[t] = r[t] || function () { (r[t].q = r[t].q || []).push(arguments[0]) };
    f = e.createElement(p), y = e.getElementsByTagName('head')[0];
    f.id = t; f.src = i; f.async = 1; y.appendChild(f);
  }(window, document, 'script', 'rs', 'https://widget.subscriptions.reptify.com/static/main.js?v=${Date.now()}');

  rs({
    apiUrl:"https://api.subscriptions.reptify.com/graphql",
    apiKey:${JSON.stringify(apiKey)},
    selector:"#subscriptions",
    meta:'userid',
    isLoggedIn:true
  })
</script>
`

const WebsiteActions = ({ _id, apiKey, url, onCodeGenerate }) => (
  <Space>
    <Link to={`/websites/edit/${_id}`} component={Button} key="edit" icon={<EditOutlined/>} size="small"/>
    {/* <Button key="clone" icon={<CopyOutlined />} title="Clone" size="small" onClick={() => clone(id)} /> */}
    <Button
      key="generate"
      icon={<Html5Outlined/>}
      title="Generate code"
      size="small"
      onClick={() => onCodeGenerate?.(apiKey)}
    />
    <Button key="delete" icon={<DeleteOutlined/>} size="small" type="danger" ghost/>
  </Space>
)

const ActionsDropdown = ({ _id, apiKey, onCodeGenerate }) => {
  const history = useHistory()

  const onMenuItemClick = ({ key }) => {
    switch (true) {
      case key.startsWith('/websites/edit/'):
        history.push(key)
        break
      case key === 'delete':
        break
      case key === 'generate':
        onCodeGenerate?.(apiKey)
        break
      default:
        break
    }
  }

  let menu = (
    <Menu onClick={onMenuItemClick}>
      <Menu.Item icon={<EditOutlined/>} key={`/websites/edit/${_id}`}>
        Edit
      </Menu.Item>
      <Menu.Item icon={<Html5Outlined/>} key="generate">
        Generate Code
      </Menu.Item>
      <Menu.Item icon={<DeleteOutlined/>} key="delete">
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <Button icon={<EllipsisOutlined/>} size="small"/>
    </Dropdown>
  )
}

const WebsiteSummary = (props) => {
  return (
    <>
      <div>
        <Typography.Text type="secondary">{props._id}</Typography.Text>
      </div>
      <div>
        <Typography.Text strong>{props.name}</Typography.Text>
      </div>
      <div>
        <Typography.Text type="secondary">
          <small>{props.apiKey}</small>
        </Typography.Text>
      </div>
    </>
  )
}

export default function WebsiteList() {
  const [selected, setSelected] = useState(null)
  const [isCopied, setCopied] = useClipboard(generateCode(selected))
  const isSmallScreen = useMedia({ query: '(max-width: 599px)' })

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title="Websites"
        extra={[
          <Link to="/websites/add" key="add" component={Button} type="primary">
            New Website
          </Link>,
        ]}
      />
      <Grid
        columns={[
          {
            title: 'Website',
            render: (website) => <WebsiteSummary {...website} />,
            mobileOnly: true,
          },
          {
            render: (props) => <ActionsDropdown {...props} onCodeGenerate={setSelected}/>,
            mobileOnly: true,
            align: 'right',
          },
          {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'API Key',
            dataIndex: 'apiKey',
            key: 'apiKey',
          },
          {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            align: 'right',
            render: (_, website) => <WebsiteActions {...website} onCodeGenerate={setSelected}/>,
          },
        ]}
        query={WEBSITES_QUERY}
      />
      <Modal
        title="Code for embedding"
        footer={null}
        onCancel={() => setSelected(null)}
        visible={selected !== null}
        width={isSmallScreen ? '95%' : '70%'}
      >
        <Highlight>
          {generateCode(selected)}
        </Highlight>
        <Space align="center">
          <Button ghost type="primary" icon={<CopyOutlined/>} onClick={setCopied}>
            copy
          </Button>
          {isCopied && <Alert banner type="success" message="Code has been copied to clipboard"/>}
        </Space>
      </Modal>
    </Layout>
  )
}